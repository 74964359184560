import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DocumentsListComponent } from './components/documents-list/documents-list.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { DocumentsRoutingModule } from './documents-routing.module';
import { AngularMaterialModule } from '../angular-material.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { LetDirective } from 'src/app/core/utils/let-directive';
import { DocumentsStoreModule } from './store/module';
import { DocUploadModalComponent } from './components/modals/doc-upload-modal/doc-upload-modal.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DocEditModalComponent } from './components/modals/doc-edit-modal/doc-edit-modal.component';
import { TAIGA_UI_MODULES } from 'src/app/shared/taiga.module';
import { DocSendReportModalComponent } from './components/modals/doc-send-report-modal/doc-send-report-modal.component';
import { DocTypesStoreModule } from '../doc-types/store/module';

@NgModule({
  declarations: [
    DocumentsComponent,
    DocumentsListComponent,
    DocUploadModalComponent,
    DocEditModalComponent,
    DocSendReportModalComponent,
  ],
  imports: [
    DocumentsRoutingModule,
    DocTypesStoreModule,
    SharedModule,
    ReactiveFormsModule,
    CommonModule,
    AngularMaterialModule,
    MatIconModule,
    TranslocoModule,
    PipesModule,
    DocumentsStoreModule,
    LetDirective,
    NgxFileDropModule,
    TAIGA_UI_MODULES,
  ],
})
export class DocumentsModule {}
